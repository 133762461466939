import {
    Card,
    Container,
    Row,
    Col,
    Image,
    Navbar,
    Nav,
    Button,
    Collapse,
    Carousel,
    Modal,
    Dropdown
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebook, faGithub, faSnapchat, faTwitter, faReddit,faTelegram, faWhatsapp,faSpotify,faDiscord, faLinkedin, faTiktok, faYoutube, faKickstarter, faPaypal, faSoundcloud, faTwitch,faTumblr,faSteam,faPinterest,faEtsy,faAmazon, faGoogle} from '@fortawesome/free-brands-svg-icons';
import { BsBank } from "react-icons/bs";
import {RiLockPasswordFill} from "react-icons/ri"
import { ToastContainer, toast } from 'react-toastify';
import {faPencil,faPlus,faCheck, faXmark, faSpinner, faLink} from '@fortawesome/free-solid-svg-icons'
import {Link, useParams, useNavigate} from "react-router-dom";
import './VisitorView.css';
import axios from 'axios';
import ErrorPage from "./ErrorPage";
import { useState, useEffect, useRef } from "react";
import icon from './icon.svg';
import PinInput from 'react-pin-input';













const VisitorView = ({isHome, onLanguageChange}) => {
 
    const [user, setUser] = useState({
      icons: [],
      // other properties
    });
    const [language,setLanguage] = useState('pl')

    const words = {
      pl: {
        login: 'Zaloguj',
        address: 'Adres',
        savecontact: 'Zapisz kontakt',
        modalPinHeader: 'Ten kontakt jest chroniony PINEM',
        modalPinText: 'Wprowadź 4-cyfrowy PIN, aby zapisać kontakt',
        modalPinButton: 'Wprowadź',
        bottomText: 'Zamów swoją kartę na',
        wrongPin: 'Nieprawidłowy PIN',
        tooManyRequests : 'Przekroczono ilość prób.',
        reviewus : 'Oceń nas',
        jobContent: 'Stanowisko:'
      },
      en: {
        login: 'Login',
        address: 'Address',
        savecontact: 'Save contact',
        modalPinHeader: 'This contact is protected by PIN',
        modalPinText: 'Enter a 4-digit PIN to save the contact',
        modalPinButton: 'Enter',
        bottomText: 'Order your card at',
        wrongPin: 'Invalid PIN',
        tooManyRequests : 'Too many attemps',
        reviewus : 'Rate us',
        jobContent: 'Job title:'
        // Dodaj więcej słów po angielsku...
      }
    };
    


    
    const [urlPart, setUrlPart] = useState('');
    const [openVideoButton,setOpenVideoButton] = useState(false);
    const [openReviewButton, setOpenReviewButton] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [countPhoto,setCountPhoto] = useState(0);
  const [userExists, setUserExists] = useState(false);
  const [ifPathUser,setPathUser] = useState('');
  useEffect(() => {
    const urlParts = window.location.pathname.split('/');
    setUrlPart(urlParts[urlParts.length - 1]);
    setPathUser(urlParts[1].toLowerCase());
    

  }, []);
  const [profile, setProfile] = useState({
    profilePicture: '',
    firstName: '',
    lastName: '',
    email: '',
    description: '',
    phone: '',
    company: '',
    blocked: '',
    url: '',
    icons: '',
    exist: false,
    customlink: '',
    keylink: '',
    contactname: '',
    contactsurname: '',
    contactphone: '',
    contactemail: '',
    photo1: '',
    photo2: '',
    photo3: '',
    posturl: '',
    firmName: '',
    firmPersonalName: '',
    firmBio: '',
    firmAdress: '',
    firmNip: '',
    firmRegon: '',
    firmPicture: '',
    firmIcons: '',
    firmCity : '',
    firmPost : '',
    firmHeader : '',
    billing1: '',
    billing2: '',
    billing3: '',
    videoUrl : '',
    reviewUrl : '',
    jobtitle : '',
    jobDisplay : '',
  });

  const [spinner,setSpinner] = useState(true);
  const updateProfileData = (data) => {
    setProfile(prevProfile => ({
      ...prevProfile, // Preserve the existing properties
      profilePicture: data.picture || prevProfile.profilePicture,
      firstName: data.firstName || prevProfile.firstName,
      lastName: data.lastName || prevProfile.lastName,
      email: data.email || prevProfile.email,
      description: data.bio || prevProfile.description,
      phone: data.phone || prevProfile.phone,
      company: data.company || prevProfile.company,
      blocked: data.blocked || prevProfile.blocked,
      url: data.url || prevProfile.url,
      icons: data.icons || prevProfile.icons,
      exist: data.exist || prevProfile.exist,
      customlink: data.customlink || prevProfile.customlink,
      keylink: data.keylink || prevProfile.keylink,
      contactname: data.contactname || prevProfile.contactname,
      contactsurname: data.contactsurname || prevProfile.contactsurname,
      contactphone: data.contactphone || prevProfile.contactphone,
      contactemail: data.contactemail || prevProfile.contactemail,
      jobtitle : data.jobtitle || prevProfile.jobtitle,
      photo1: data.photo1 || prevProfile.photo1,
      photo2: data.photo2 || prevProfile.photo2,
      photo3: data.photo3 || prevProfile.photo3,
      posturl: data.posturl || prevProfile.posturl,
      firmName: data.firmName || prevProfile.firmName,
      firmPersonalName: data.firmPersonalName || prevProfile.firmPersonalName,
      firmBio: data.firmBio || prevProfile.firmBio,
      firmAdress: data.firmAdress || prevProfile.firmAdress,
      firmNip: data.firmNip || prevProfile.firmNip,
      firmRegon: data.firmRegon || prevProfile.firmRegon,
      firmPicture: data.firmPicture || prevProfile.firmPicture,
      firmHeader : data.firmHeader || prevProfile.firmHeader,
      firmPost : data.firmPost || prevProfile.firmPost,
      firmCity : data.firmCity || prevProfile.firmCity,
      jobDisplay : data.jobDisplay || prevProfile.jobDisplay,
      billing1: data.billing1 || prevProfile.billing1,
      billing2: data.billing2 || prevProfile.billing2,
      billing3: data.billing3 || prevProfile.billing3,
      videoUrl : data.videoURL || prevProfile.videoUrl,
      reviewUrl : data.reviewUrl || prevProfile.reviewUrl
    }));
  };

  // Update the profile state with user data
  

  
  useEffect(()=> {
    
    if(isHome === true) {
      setProfile({
        profilePicture: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FTagU%20(150%20×%20150px).png?alt=media&token=deb24444-ccca-4c28-a1a7-b6d66c6ece26&_gl=1*1ky0i53*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NTU2NjQ5Ny4xNi4xLjE2ODU1NjY4MDYuMC4wLjA.',
        firstName: 'TagU',
        lastName: '',
        description : 'Zamów swoją wizytówkę na sklep.tagu.pl',
        email: '@example.com',
        icons: {
          
          instagram: 'https://www.instagram.com/tagu.card/',
          own : 'https://sklep.tagu.pl'
          
        },
        contactname : 'TagU',
        contactemail : 'tagu.kontakt@gmail.com',
        contactsurname : '',
        contactphone : '',
        
        url : 'https://sklep.tagu.pl',
        company : 'TagU',



        exist: true,
        keylink: 'TagU',
        theme: 'red',
        visitorType: 'userView',
        
        photo1 : "https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FProjekt%20bez%20nazwy%20(12).png?alt=media&token=3e20a8ed-62a9-4717-af32-90e7f7f021a1",
        

      });

      setOpenGalleryButton(true)
      setSelectedTheme('white');
      setOpenReviewButton(false);
      
      setSelectedOption('userView');
      setOpenContactButton(true);

      setCountPhoto(1);
      setSpinner(false);
      setCardStyles({
        coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlack%20Minimalist%20Linktree%20Background2.png?alt=media&token=37f0f254-9cc3-4d0b-ba54-56060d4675ca',
        cardBackground: '#EBEBEB',
        textCardColor: '#001014',
        buttonContactColor: '#001014',
        textButtonContactColor: '#EBEBEB',
        navbarBackground: '#001014',
        navbarTextColor: '#EBEBEB',
        editLinksButtonColor: '#001014',
        profilePictureBorder: '5px solid #001014',
        iconsColor: '#001014'
      });
    }
  },[isHome])


  const [displayBankLogo1,setDisplayBankLogo1] = useState('')
  const [displayBankLogo2,setDisplayBankLogo2] = useState('')
  const [displayBankLogo3,setDisplayBankLogo3] = useState('')
  useEffect(() => {
    const generateToken = async () => {
      try {
        if(urlPart && ifPathUser === 'users') {
        const response = await axios.post('https://api.tagu.pl/generate_token', { urlPart });
        const token = response.data;
        localStorage.setItem('visitortoken', token); // Zapisz token w localStorage
        }
      } catch (error) {
        console.error('Błąd generowania tokenu:', error);
      }
    };
    
    const fetchData = async () => {
      const token = localStorage.getItem('visitortoken');
      try {
        if(urlPart && token) {
        
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await axios.get(`https://api.tagu.pl/users`, { headers });
          
          const { data } = response;
          setLanguage(data.language);
          localStorage.setItem("language", data.language);
          if(response.data.blocked===true) {
            navigate('/zablokowany');
          }
          
          if(response.data.reg===true) {
            navigate('/rejestracja');
          }
                      
                      const firstCharIsLetter1 = isNaN(data.billing1[0]);

                      const startIndex1 = firstCharIsLetter1 ? 5 : 3;
                      const endIndex1 = firstCharIsLetter1 ? 9 : 7;

                      const firstCharIsLetter2 = isNaN(data.billing2[0]);


                      const startIndex2 = firstCharIsLetter2 ? 5 : 3;
                      const endIndex2 = firstCharIsLetter2 ? 9 : 7;

                      const firstCharIsLetter3 = isNaN(data.billing3[0]);


                      const startIndex3 = firstCharIsLetter3 ? 5 : 3;
                      const endIndex3 = firstCharIsLetter3 ? 9 : 7;

                      const bankCode1 = data.billing1.substring(startIndex1,endIndex1);
                      const bankCode2 = data.billing2.substring(startIndex2,endIndex2);
                      const bankCode3 = data.billing3.substring(startIndex3,endIndex3);

                      
                      let bankImageUrl1 = '';
                      let bankImageUrl2 = '';
                      let bankImageUrl3 = '';
                      switch (bankCode1) {
                        case '1010' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
                            break;
                          case '1160' : 
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
                            break;
                          case '1020':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
                            break;
                          case '1050':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
                            break;
                          case '1090':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
                            break;
                          case '1140':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
                            break;
                          case '1240':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
                            break;
                          case '1320' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
                            break;
                          case '1870' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
                            break;
                          case '1930' :
                            bankImageUrl1 =
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
                            break;
                          case '1940':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
                            break;
                          case '2030' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
                            break;
                          case '2490' :
                            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
                            break;
                          case '3250' :
                            if(data.billing1.startsWith('LT')) {
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
                            }
                          default:
                            break;
                        }
                      
                      setDisplayBankLogo1(bankImageUrl1);
                      switch (bankCode2) {
                        case '1010' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
                            break;
                          case '1160' : 
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
                            break;
                          case '1020':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
                            break;
                          case '1050':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
                            break;
                          case '1090':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
                            break;
                          case '1140':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
                            break;
                          case '1240':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
                            break;
                          case '1320' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
                            break;
                          case '1870' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
                            break;
                          case '1930' :
                            bankImageUrl2 =
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
                            break;
                          case '1940':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
                            break;
                          case '2030' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
                            break;
                          case '2490' :
                            bankImageUrl2 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
                            break;
                          case '3250' :
                            if(data.billing2.startsWith('LT')) {
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
                            }
                          default:
                            break;
                        }
                      
                      setDisplayBankLogo2(bankImageUrl2);
                      switch (bankCode3) {
                        case '1010' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
                            break;
                          case '1160' : 
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
                            break;
                          case '1020':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
                            break;
                          case '1050':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
                            break;
                          case '1090':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
                            break;
                          case '1140':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
                            break;
                          case '1240':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
                            break;
                          case '1320' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
                            break;
                          case '1870' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
                            break;
                          case '1930' :
                            bankImageUrl3 =
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
                            break;
                          case '1940':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
                            break;
                          case '2030' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
                            break;
                          case '2490' :
                            bankImageUrl3 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
                            break;
                          case '3250' :
                            if(data.billing3.startsWith('LT')) {
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
                            }
                          default:
                            break;
                        }
                      setDisplayBankLogo3(bankImageUrl3);

                      if (data.photo1 !== '') {
                        setCountPhoto(prevCount => prevCount + 1);
                      }
                    
                      if (data.photo2 !== '') {
                        setCountPhoto(prevCount => prevCount + 1);
                      }
                    
                      if (data.photo3 !== '') {
                        setCountPhoto(prevCount => prevCount + 1);
                      }

          updateProfileData(data);
          if (userID) {
            const fetchData = async () => {
              
              const visitToken = localStorage.getItem('visitortoken');
              if(visitToken) {
              try {
                
                
                const themeResponse = await axios.get(`https://api.tagu.pl/getTheme`, {
                headers: {
                  Authorization: `Bearer ${visitToken}`,
                },
              });
            
  
                const { theme } = themeResponse.data;
                
                // Ustawienie tematu jako wybrany temat
                setSelectedTheme(theme);
                
                // Ustawienie odpowiednich stylów karty na podstawie tematu
                if (theme === 'white') {
                  setCardStyles({
                    coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlack%20Minimalist%20Linktree%20Background2.png?alt=media&token=37f0f254-9cc3-4d0b-ba54-56060d4675ca',
                    cardBackground: '#EBEBEB',
                    textCardColor: '#001014',
                    buttonContactColor: '#001014',
                    textButtonContactColor: '#EBEBEB',
                    navbarBackground: '#001014',
                    navbarTextColor: '#EBEBEB',
                    editLinksButtonColor: '#001014',
                    profilePictureBorder: '5px solid #001014',
                    iconsColor: '#001014'
                  });
                } else if (theme === 'lightgrey') {
                  setCardStyles({
                    coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlue%20Watercolor%20Linktree%20Background%20(3).png?alt=media&token=e5c3cf34-bd5a-449e-a987-0e3b765f5baf',
                    cardBackground: '#E1E3D5',
                    textCardColor: '#323358',
                    buttonContactColor: '#323358',
                    textButtonContactColor: '#e1b94b',
                    navbarBackground: '#323358',
                    navbarTextColor: '#e1b94b',
                    editLinksButtonColor: '#323358',
                    profilePictureBorder: '5px solid #323358',
                    iconsColor: '#323358'
                  });
                } else if (theme === 'purple') {
                  setCardStyles({
                    coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FProjekt%20bez%20nazwy%20(11).png?alt=media&token=4762d3cb-4f26-4aa7-818c-8eb3140e1bbb',
                    cardBackground: '#FCECC9',
                    textCardColor: '#814692',
                    buttonContactColor: '#814692',
                    textButtonContactColor: '#FCECC9',
                    navbarBackground : '#814692',
                    navbarTextColor : '#FCECC9',
                    editLinksButtonColor : '#814692',
                    profilePictureBorder : '5px solid #814692',
                    iconsColor : '#814692'
                  });
                }
                else if (theme === 'green') {
                  setCardStyles({
                    coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FUntitled%20design%20(5).png?alt=media&token=d141591d-2fca-45d2-bf26-bd094c2c9153',
                    cardBackground: '#F6EDD9',
                    textCardColor: '#567956',
                    buttonContactColor: '#567956',
                    textButtonContactColor: '#E1E3D5',
                    navbarBackground: '#567956',
                    navbarTextColor: '#F6EDD9',
                    editLinksButtonColor: '#567956',
                    profilePictureBorder: '5px solid #567956',
                    iconsColor: '#567956'
                  });
                }
              
              } catch (error) {
                
              }
            
            
            };
          }
          const fetchData1 = async () => {
            try {
              
                const token = localStorage.getItem('visitortoken');
                if(token) {
                const response = await axios.get(`https://api.tagu.pl/getUserSwitches`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
  
                const { contactswitch, galleryswitch, postswitch, contactpinswitch, videoswitch,reviewswitch, visitorType } = response.data;
                setSelectedOption(visitorType)
                setOpenContactButton(contactswitch);
                setOpenGalleryButton(galleryswitch);
                setOpenPostButton(postswitch);
                setOpenContactPinButton(contactpinswitch);
                setOpenVideoButton(videoswitch);
                setOpenReviewButton(reviewswitch)
                setTypeLoading(false);
                
                
              }
            
            } catch (error) {
              
              
            }
          };
        
          fetchData1();
        
            fetchData();
          }
          
          setSpinner(false);
          
      }
      } catch (error) {
        console.error('Błąd pobierania danych:', error);
        navigate('/error')
      }
    };
  
    if (urlPart) {
      generateToken();
      setTimeout(() => {
        fetchData();
      }, 1000);
    }
  }, [urlPart]);
  


  
  
  

  
  
  
  
  
    
    

  

    
    
    
    let { userID } = useParams();
    
    
// ...

useEffect(() => {
  if (profile.customlink) {
    navigate(`/users/${profile.customlink}`);
    userID = profile.customlink;
  }
  
}, [profile.customlink, navigate]);



function validateUrl(url) {
  const instagramPostRegex = /^https?:\/\/(www\.)?instagram\.com\/p\/[a-zA-Z0-9_-]+(\/embed)?\/?.*$/;
  return instagramPostRegex.test(url);
}

  const isValidUrl = profile.posturl && validateUrl(profile.posturl);
  const [pinInput, setPinInput] = useState('');
  const [pinValue, setPinValue] = useState('');
  const [pins, setPins] = useState(['', '', '', '']);
  const inputsRef = useRef([]);

  const handlePinChange = (e, index) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');
  
    const newPins = [...pins];
    newPins[index] = value;
    setPins(newPins);
  
    if (value.length === 1 && index < 3) {
      // Dodanie znaku spowodowało, że pole ma 1 znak
      if (index === 3 && value.length === 1) {
        // Jeśli jesteśmy w ostatnim polu, opuść kursor z pola
        inputsRef.current[index].blur();
      } else {
        // Przenieś kursor do następnego pustego pola lub do tego samego pola
        const nextEmptyIndex = newPins.findIndex((pin, i) => i > index && !pin);
        inputsRef.current[nextEmptyIndex !== -1 ? nextEmptyIndex : index + 1].focus();
      }
    }
  };
  
  
  
  

    
    
  
  const iconMap = {
    facebook: faFacebook,
    instagram: faInstagram,
    github: faGithub,
    snapchat: faSnapchat,
    twitter : faTwitter,
    whatsapp : faWhatsapp,
    reddit : faReddit,
    telegram : faTelegram,
    spotify : faSpotify,
    discord : faDiscord,
    snapchat : faSnapchat,
    linkedin : faLinkedin,
    tiktok : faTiktok,
    youtube : faYoutube,
    kickstarter : faKickstarter,
    paypal : faPaypal,
    soundcloud : faSoundcloud,
    twitch : faTwitch,
    tumblr : faTumblr,
    steam : faSteam,
    pinterest : faPinterest,
    booksy : faEtsy,
    amazon : faAmazon,
    bereal : <img src={icon} style={{width:"18px"}}></img>,
    own : faLink,
    own1 : faLink



    // itd.
  };


  const [openContactButton, setOpenContactButton] = useState(false);
  const [openGalleryButton, setOpenGalleryButton] = useState(false);
  const [openPostButton, setOpenPostButton] = useState(false);

  const [theme,setSelectedTheme] = useState('');

  const [cardStyles, setCardStyles] = useState({
    coverPhoto: '',
    cardBackground: '',
    textCardColor: '',
    buttonContactColor: '',
    textButtonContactColor: '',
    navbarBackground : '',
    navbarTextColor : '',
    editLinksButtonColor : '',
    profilePictureBorder : '',
    iconsColor:'',
    
  });
      useEffect(() => {
        
      }, [userID]);

      const notify2 = (errorMessage) => {
        toast.success(errorMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      const [openContactPinButton, setOpenContactPinButton] = useState(false);
      const [selectedOption,setSelectedOption] = useState('');
      const [isTypeLoading,setTypeLoading] = useState(true);
      
      const [downloadAllowed,setDownloadAllowed] = useState(false);
      const downloadHandler = (downloadAllowed1) => {
        
        if(openContactPinButton === false || downloadAllowed1 ===true) {
          
          const vCard = `BEGIN:VCARD
VERSION:3.0
N:${profile.contactsurname};${profile.contactname};;;
FN:${profile.contactname} ${profile.contactsurname}
EMAIL:${profile.contactemail}
TEL:${profile.contactphone}
ORG:${profile.company}
URL:${profile.url}

TITLE:${profile.jobtitle}

END:VCARD`;
        const encodedVCard = encodeURIComponent(vCard);
        const dataUri = `data:text/vcard;charset=utf-8,${encodedVCard}`;
      
        window.location.href = dataUri;
        }
        else {
          setPinInput(true);
      };
    };

      
  
  
  
  
  const { cardBackground, textCardColor, buttonContactColor, textButtonContactColor, navbarBackground, navbarTextColor,editLinksButtonColor, profilePictureBorder,iconsColor } = cardStyles;

  const carouselRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const calculateMaxHeight = (carouselChildren) => {
    let max = 0;
  
    carouselChildren.forEach((child) => {
      const image = child.querySelector('img');
      if (image && image.offsetHeight > max) {
        max = image.offsetHeight;
      }
    });
  
    return max;
  };
  const notify3 = (message) => {
    toast.error(message,{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    })
  }
  const handleChange = (value, index) => {
    const pin = value.join('');
    
  };
  const [tempPin,setTempPin] = useState('');
  /*
  const handlePinChange = (value, index) => {
    const inpin = document.getElementById("pinInput");
    if(inpin) {
    inpin.setAttribute("type", "numeric");
    }
    setPinValue(value);
  
  
};
*/
  
  useEffect(() => {
    const handleResize = () => {
      const carouselChildren = Array.from(carouselRef.current?.children || []);
      setMaxHeight(calculateMaxHeight(carouselChildren));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageLoad = () => {
    const carouselChildren = Array.from(carouselRef.current?.children || []);
    setMaxHeight(calculateMaxHeight(carouselChildren));
  };

  const verifyPinHandler = async () => {
    const pinVal = pins.join('');
    setPinInput(false);
    try {
      const token = localStorage.getItem('visitortoken');
      const response = await axios.post(
        'https://api.tagu.pl/verifyPin',
        {
          pin: pinVal,
          userID: userID
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      const { success } = response.data;
      if (success) {
        downloadHandler(true);
      } else {
        notify3(words[language].wrongPin);
      }
  
      // Czyszczenie wartości inputów PINu
      const newPins = ['', '', '', ''];
      setPins(newPins);
      inputsRef.current[0].focus(); // Ustawienie fokusu na pierwsze pole PINu
    } catch (error) {
        if(error.response && error.response.status===429) {
          notify3(words[language].tooManyRequests)
        }
    }
  };

  const handleChangeLanguage = (language) => {
    // Tutaj umieść kod obsługujący zmianę języka
    setLanguage(language);
    localStorage.setItem("language",language);
    
    

  };
  
  




    
  if(spinner || !profile || selectedOption ==='' || theme ==='') {
    return (
      <div className='spinner-center-loading'>
        <FontAwesomeIcon icon={faSpinner} spin size="5x"/>
      </div>
    )
  }

  
   {
  
  return (
    
    <div translate="no" className='card-container' style={{border: 'none'}}>
    
    
      
      <Card style={{backgroundColor : cardBackground, color: textCardColor, border: 'none'}} className="visitor-card">
      <Navbar variant="dark" className="justify-content-between card-header" style={{backgroundColor: navbarBackground, color: textCardColor, borderRadius: '0'}}>
  <Navbar.Brand style={{color: navbarTextColor, display: 'flex', alignItems: 'center'}}>
    {selectedOption === 'firmView' ? (
      <>{profile.firmHeader}</>
    ) : (
      <>TagU</>
    )}
    <div style={{ marginLeft: '10px' }}>
      <img src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpolandflag.png?alt=media&token=8174f61e-4c20-4e83-a7ec-9f14c4e2c552" alt="Polish" style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }} onClick={() => handleChangeLanguage('pl')} />
      <img src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fenglandflag.png?alt=media&token=3149d47d-3619-4e59-8dad-7b61ce48bbf0" alt="English" style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }} onClick={() => handleChangeLanguage('en')} />
    </div>
  </Navbar.Brand>
  <Nav>
    <Link className="logowanie" to="/zaloguj">
      <a style={{color: navbarTextColor}}>{words[language].login}</a>
    </Link>
  </Nav>
</Navbar>

              <div className="profile-image-container">
                  <Image
                      className="cover-image"
                      src={cardStyles.coverPhoto}
                      fluid/>
                  <Image style={{border : profilePictureBorder}} className="profile-image" src={profile.profilePicture}/>
              </div>

              <Card.Body>
                  <Card.Title className="name-info mb-1">
                    
                 
                  {selectedOption==='firmView'  ? (<>{profile.firstName}  {profile.lastName}</>) : (<>{profile.firstName} {profile.lastName}</>)}
                
                
                      
                  </Card.Title>
                  
                  {selectedOption==='firmView' && (<Card.Text className="firm-personal-name">
                     <h5>
                        {profile.firmName !== ' ' ? `${profile.firmName}` : ''}
                      </h5>
                  </Card.Text>)}

                  {selectedOption === 'firmView' && profile.jobDisplay !== ' ' && (
                  <Card.Text className="firm-job">
                    <b>{words[language].jobContent}</b> {profile.jobDisplay}
                  </Card.Text>
                )}

                {selectedOption === 'firmView' && 
                (profile.firmAdress !== ' ' || profile.firmPost !== ' ' || profile.firmCity !== ' ') && (
                  <Card.Text className="firm-adress">
                    <b>{words[language].address}: {''}</b>
                    {/* Zbudowanie pełnego adresu z dostępnych części */}
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${profile.firmAdress} ${profile.firmPost} ${profile.firmCity}`.trim())}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {`${profile.firmAdress !== ' ' ? profile.firmAdress : ''}${profile.firmPost !== ' ' ? `, ${profile.firmPost}` : ''}${profile.firmCity !== ' ' ? ` ${profile.firmCity}` : ''}`}
                    </a>
                  </Card.Text>
                )}


                 {selectedOption === 'firmView' && profile.firmNip !== ' ' && (
                  <Card.Text className="firm-nip">
                    <b>NIP:</b> {profile.firmNip}
                  </Card.Text>
                )}
                 {selectedOption === 'firmView' && profile.firmRegon !== ' ' && (
                  <Card.Text className="firm-regon">
                    <b>Regon:</b> {profile.firmRegon}
                  </Card.Text>
                )}
                {selectedOption === 'firmView' && profile.billing1 !== ' ' && (
                  <Card.Text className="billing1">
                     {displayBankLogo1 ? (<><img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={displayBankLogo1}></img> {" "} {profile.billing1}</>) :  (<><BsBank>   </BsBank> {' '} {profile.billing1} </>)}
                  </Card.Text>
                )}
                {selectedOption === 'firmView' && profile.billing2 !== ' ' && (
                  <Card.Text className="billing2">
                    {displayBankLogo2 ? (<><img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={displayBankLogo2}></img> {" "} {profile.billing2}</>) :  (<><BsBank>   </BsBank> {' '} {profile.billing2} </>)}
                  </Card.Text>
                )}
                {selectedOption === 'firmView' && profile.billing3 !== ' ' && (
                  <Card.Text className="billing1">
                    {displayBankLogo3 ? (<><img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={displayBankLogo3}></img> {" "} {profile.billing3}</>) :  (<><BsBank>   </BsBank> {' '} {profile.billing3} </>)}
                  </Card.Text>
                )}

                {selectedOption === 'userView' && (
                        <Card.Text className="bio-content">
                          <pre style={{fontFamily : "inherit"}} className="bio-content">
                          {profile.description}{' '}
                          </pre>
                          
                        </Card.Text>
                      )}
                      {selectedOption === 'firmView' && profile.firmBio && (
                        <Card.Text className="bio-content" >
                          <pre style={{fontFamily : "inherit"}} className="bio-content">
                          {profile.firmBio}{' '}
                          </pre>
                          
                        </Card.Text>
                      )}
                   
                  
                   





                  
                  <div className="text-center mb-1">
                  {openContactButton && !pinInput && (
                  <Button
                    style={{
                      color: textButtonContactColor,
                      backgroundColor: buttonContactColor,
                      border: buttonContactColor
                    }}
                    onClick={downloadHandler}
                    className="wide-btn mb-1"
                    variant="primary"
                    href="#target"
                  >
                    {words[language].savecontact}
                  </Button>
                )}
                </div>
                <div className="text-center mb-1">
                  {openReviewButton && (
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "#ebb734",
                      border: "black"
                    }}
                    
                    className="wide-btn mb-1"
                    variant="primary"
                    href={profile.reviewUrl}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon> {words[language].reviewus}
                  </Button>
                  )}
               
                </div>

{pinInput && (
  <div className="modal-container">
  <Modal   show={pinInput} onHide={() => {setPinInput(false); const newPins = ['', '', '', ''];
      setPins(newPins);}} centered>
    
    <Modal.Body style={{backgroundColor : cardBackground}} >
    <div class="main-content text-center">
    
                <a onClick={()=> {setPinInput(false); const newPins = ['', '', '', ''];
      setPins(newPins);}} href="#" class="close-btn" data-dismiss="modal" aria-label="Close">
                    <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                  </a>

                <div class="warp-icon mb-4">
                  <span style={{color:textCardColor}} class="icon-lock2"><RiLockPasswordFill></RiLockPasswordFill></span>
                </div>
                <form action="#">
                  <label style={{color:textCardColor}} for="">{words[language].modalPinHeader}</label>
                  <p style={{color:textCardColor}} class="mb-4">{words[language].modalPinText}</p>
                  <div class="form-group mb-4">
                  <form>
      <label>
        
        <div>
          {pins.map((pin, index) => (
            <input
              key={index}
              type="tel" // Używamy type="tel", aby ograniczyć wprowadzanie tylko do cyfr
              maxLength={1}
              value={pin}
              onChange={(e) => handlePinChange(e, index)}
              className='pinInputs'
              style={{borderColor: textCardColor, color: textCardColor }}
              ref={(input) => (inputsRef.current[index] = input)}
              autoFocus={index === 0}
            />
          ))}
        </div>
      </label>
      <br />
      
    </form>
                  </div>

                  <div class="d-flex">
                    <div class="mx-auto">
                    <Button
        className="modal-submit-btn"
        onClick={verifyPinHandler}
        variant="primary"
        href="#target"
        style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
      >
        {words[language].modalPinButton}
      </Button>
                    </div>
                  </div>
                </form>
              
            </div>
    </Modal.Body>
   
  </Modal>
</div>

)}


                  
                  {Object.values(profile.icons).some(value => value !== ' ') && (
                  <div className="social-icons" style={{ border: Object.values(profile.icons).filter(value => value !== ' ').length >= 4 ? '1px solid #00000040' : 'none' }}>
                    {Object.entries(profile.icons).map(([key, value]) => {
if (value !== ' ') {
  return (
    <a href={value} target="_blank" rel="noopener noreferrer">
     {key === 'booksy' ? (
          <svg style={{borderRadius:"15%", width: "60px"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 400.000000 400.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          fill={iconsColor} stroke="none">
          <path d="M0 2000 l0 -2000 2000 0 2000 0 0 2000 0 2000 -2000 0 -2000 0 0
          -2000z m1956 885 c11 -15 14 -69 14 -265 0 -136 3 -249 6 -253 3 -3 41 12 84
          34 111 57 207 75 321 60 155 -20 271 -79 368 -188 177 -198 214 -520 88 -758
          -47 -88 -156 -194 -247 -238 -116 -57 -185 -72 -335 -72 -106 0 -145 5 -210
          23 -158 46 -239 92 -548 315 -81 58 -186 125 -232 148 -77 38 -93 42 -158 42
          -61 0 -82 -4 -124 -27 -47 -26 -123 -109 -123 -134 0 -24 -43 -33 -152 -30
          l-113 3 -3 32 c-6 63 85 200 183 274 123 93 311 136 459 106 113 -23 182 -60
          351 -187 325 -244 457 -313 642 -335 169 -19 354 78 420 221 34 75 42 194 19
          284 -73 285 -390 385 -588 185 -74 -74 -100 -139 -107 -267 -7 -132 -14 -142
          -78 -108 -25 13 -70 41 -99 62 l-54 39 -2 499 c-2 274 -1 509 2 521 8 31 34
          39 123 36 65 -2 81 -6 93 -22z"/>
          </g>
          </svg>
        ) : key === 'bereal' ? (
          <svg style={{ width: "60px" }} version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 249.000000 243.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,243.000000) scale(0.100000,-0.100000)"
            fill={iconsColor} stroke="none">
            <path d="M610 2354 c-81 -9 -157 -27 -202 -49 -145 -70 -244 -193 -279 -350
            -20 -88 -20 -1415 0 -1495 48 -191 188 -331 381 -381 62 -17 1276 -25 1412
            -10 124 14 191 37 273 96 120 85 187 210 206 388 15 131 6 1360 -10 1412 -60
            198 -190 327 -381 376 -32 9 -237 13 -705 14 -363 1 -676 1 -695 -1z m-15
            -959 c52 -25 72 -96 40 -142 -14 -20 -13 -24 10 -47 20 -20 25 -35 25 -74 0
            -41 -5 -53 -31 -78 -29 -28 -34 -29 -145 -32 l-114 -4 0 196 0 196 91 0 c61 0
            102 -5 124 -15z m652 -4 c43 -20 73 -62 73 -101 0 -28 -25 -76 -49 -92 -19
            -13 -18 -16 21 -83 56 -98 56 -95 12 -95 -38 0 -40 2 -79 73 -38 68 -42 72
            -75 72 l-35 0 -3 -72 -3 -73 -34 0 -35 0 0 195 0 195 83 0 c59 0 94 -6 124
            -19z m778 -176 l0 -190 -37 -3 -38 -3 0 196 0 196 38 -3 37 -3 0 -190z m-1096
            69 c28 -20 51 -72 51 -114 l0 -30 -90 0 c-96 0 -110 -9 -70 -45 16 -14 30 -17
            74 -12 52 5 55 4 61 -20 7 -31 -4 -40 -63 -49 -59 -9 -127 18 -153 60 -23 38
            -25 125 -4 166 8 16 28 36 43 44 37 21 122 20 151 0z m643 5 c24 -13 57 -83
            58 -121 l0 -28 -90 0 c-96 0 -110 -9 -70 -45 15 -14 31 -17 70 -13 42 4 53 2
            60 -13 15 -26 13 -29 -25 -45 -46 -19 -81 -17 -133 6 -96 44 -105 205 -14 254
            32 18 115 21 144 5z m287 -4 c39 -20 51 -61 51 -171 l0 -94 -40 0 c-27 0 -40
            4 -40 14 0 10 -9 9 -40 -6 -54 -27 -105 -11 -126 39 -27 62 18 112 101 113 49
            0 68 11 63 36 -4 25 -75 33 -84 10 -8 -20 -74 -22 -74 -3 0 20 30 58 54 67 34
            14 104 11 135 -5z m281 -225 c0 -39 -1 -40 -35 -40 -26 0 -35 4 -37 18 0 9 -2
            24 -3 32 -2 25 5 30 41 30 33 0 34 -1 34 -40z"/>
            <path d="M450 1300 l0 -50 41 0 c56 0 79 14 79 51 0 34 -27 49 -86 49 l-34 0
            0 -50z"/>
            <path d="M450 1135 l0 -55 55 0 c42 0 60 5 75 20 45 45 8 90 -75 90 l-55 0 0
            -55z"/>
            <path d="M1117 1343 c-4 -3 -7 -30 -7 -60 l0 -53 45 0 c85 0 117 72 48 109
            -24 12 -76 15 -86 4z"/>
            <path d="M816 1224 c-27 -26 -18 -34 39 -34 58 0 66 7 37 33 -23 21 -56 22
            -76 1z"/>
            <path d="M1468 1229 c-10 -5 -18 -17 -18 -24 0 -11 14 -15 56 -15 56 0 56 0
            42 23 -17 25 -54 32 -80 16z"/>
            <path d="M1748 1129 c-22 -13 -23 -45 -2 -53 42 -16 84 6 84 45 0 15 -7 19
            -32 19 -18 0 -41 -5 -50 -11z"/>
            </g>
            </svg>
        ) : (
          <FontAwesomeIcon
            style={{ color: iconsColor }}
            href={value}
            icon={iconMap[key]}
            className="icon"
          />
        )}
    </a>
  );
} else {
  return null;
}
})}

                            </div>
            )}


                  <div className="text-center mt-4">
                    <Collapse in={openGalleryButton}>
                      <div>
                      {((profile.photo1 && profile.photo1 !== '') || (profile.photo2 && profile.photo2 !== '') || (profile.photo3 && profile.photo3 !== '')) && (
                  <Carousel id={countPhoto===1 ? "onePhoto" : 'morePhoto'} ref={carouselRef}>
                    {profile.photo1 && profile.photo1 !== '' && (
                      <Carousel.Item>
                        <div  className="carousel-image-container rounded">
                          <img
                            className="centered-image d-block rounded"
                            src={profile.photo1}
                            
                            alt="First slide"
                            onLoad={handleImageLoad}
                          />
                        </div>
                      </Carousel.Item>
                    )}

                    {profile.photo2 && profile.photo2 !== '' && (
                      <Carousel.Item>
                        <div className="carousel-image-container rounded">
                          <img
                            className="centered-image d-block rounded"
                            src={profile.photo2}
                            alt="Second slide"
                            onLoad={handleImageLoad}
                          />
                        </div>
                      </Carousel.Item>
                    )}

                    {profile.photo3 && profile.photo3 !== '' && (
                      <Carousel.Item>
                        <div className="carousel-image-container rounded">
                          <img
                            className="centered-image d-block rounded"
                            src={profile.photo3}
                            alt="Third slide"
                            onLoad={handleImageLoad}
                          />
                        </div>
                      </Carousel.Item>
                    )}
                  </Carousel>
                )}
                      </div>
                    </Collapse>  
                  </div>
                  <div className="text-center mt-2">
                    <Collapse in={openPostButton}>
                      <div>
                        
                      {profile &&  profile.posturl && validateUrl(profile.posturl) && (
                                              <div className='igpost text-center mt-2'>
                                             
                                              <iframe
                                                title="Instagram Embed"
                                                src={profile.posturl}
                                                width="100%"
                                                height="600"
                                                frameborder="0"
                                                scrolling="no"
                                                allowtransparency="true"
                                              ></iframe>
                                           
                                          </div>
                        
                                          )}
                                          
                                          </div>
                                        </Collapse>  
                                      </div>
                                      <Collapse in={openVideoButton}> 
                          <div className='igpost text-center mt-2'>
                          {profile && profile.videoUrl && (
                           <iframe width="100%" height="600" src={profile.videoUrl} frameborder="0" allowfullscreen></iframe>
                        )}
                      </div>
                      
                    </Collapse>    
                                      <ToastContainer
                                                position="top-center"
                                                autoClose={5000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                limit = "4"
                                                pauseOnHover
                                                theme="dark"
                                              />


                        
                                            
    </Card.Body>
    
    <div>
      <p style={{textAlign:"center", fontSize:"13px"}} className='mb-1'>Copyright © 2023 TagU</p>
      <p style={{color: textCardColor, textAlign:"center", fontSize:"13px"}}>{words[language].bottomText} <a href='https://sklep.tagu.pl' style={{textDecoration:"none"}}>sklep.tagu.pl</a></p>
    </div>
  </Card>    
</div>
);
                      }
        
    
};

export default VisitorView;